import React from 'react'
import NavBar from '../NavBar/NavBar';
import AboutHero from './AboutHero/AboutHero';
import './about.css'
import Mission from './Misson/Mission';
import CoreValues from './CoreValues/CoreValues';
import Footer from '../Footer/Footer';
export default function About() {
    return (
        <>
            <NavBar />
            <div className="about-container">

                <AboutHero />
                <Mission />
                <CoreValues />
            </div>
            <div id="bottom-hero">
                <p className="btm-hero-txt">We want to help people connect more </p>
                <p className="btm-hero-txt">and have better relationships.</p>
            </div>
            <Footer />
        </>
    )
}
