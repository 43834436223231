import React from 'react'
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import './Privacy.css'
const Privacy = () => {
    return (
        <div>
            <NavBar />
            <div className="privacy-container">
                <h1>Privacy Notice</h1>
                <p>Openwit Technologies’ privacy notice describes how and why we might collect, store, use, and/or share your
                     information when you use our Services, such as when you access our website
                     or communicate with us.
                </p>
                <p>Reading this privacy notice will help you understand your privacy rights and choices.
                    If you do not agree with our policies and practices, please do not use our Services.
                </p>

                <p><span style={{ fontWeight: "500" }}>SUMMARY OF KEY POINTS</span></p>
                <p>
                    This summary provides key points from our privacy notice, but you can find out more details
                    about any of these topics by using our table of contents below to find the section you are
                    looking for.
                 </p>

                <p> <span style={{ fontWeight: "600" }}>What personal information do we process? </span>  When you visit, use, or navigate our Services,
                     we may process personal information depending on how you interact with us and the Services,
                     the choices you make, and the features you use.
                    See more details below about personal information you disclose to us.
                </p>

                <p> <span style={{ fontWeight: "600" }}> Do we process any sensitive personal information? </span>
                    We do not process sensitive personal information.
                </p>

                <p> <span style={{ fontWeight: "600" }}> How do we process your information?  </span>
                    We process your information to provide, improve, and administer our Services, communicate with you,
                    for security and fraud prevention, and to comply with the law. We may also process your information
                  for other purposes with your consent.We process your information only when we have a valid legal reason to do so.
                  See more details below about how we process your information.
                </p>

                <p> <span style={{ fontWeight: "600" }}>  In what situations and with which parties do we share personal information? </span>
                    We may share information in specific situations and with specific third parties.
                    See more details below about when and with whom we share your personal information.
                </p>

                <p> <span style={{ fontWeight: "600" }}>What are your rights?  </span> Depending on where you are located geographically,
                    the applicable privacy law may mean you have certain rights regarding your personal information.
                    See more details below about your privacy rights.
                </p>

                <p> <span style={{ fontWeight: "600" }}> How do you exercise your rights?  </span> The easiest way to exercise your rights is by submitting a data subject access request,
                or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
                </p>

                <p>Want to learn more about what we do with any information we collect? Review the privacy notice in full below.  </p>

                <h3 className="toc-header" >Table of Contents</h3>

                <div className="toc-privacy">
                    <a href="#section1">1. WHAT INFORMATION DO WE COLLECT?</a><br />
                    <a href="#section2">2. HOW DO WE PROCESS YOUR INFORMATION?</a><br />
                    <a href="#section3">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a><br />
                    <a href="#section4">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a><br />
                    <a href="#section5">5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY? WHAT INFORMATION DO WE COLLECT?</a><br />
                    <a href="#section6">6. HOW LONG DO WE KEEP YOUR INFORMATION?</a><br />
                    <a href="#section7">7. DO WE COLLECT INFORMATION FROM MINORS?</a><br />
                    <a href="#section8">8. WHAT ARE YOUR PRIVACY RIGHTS?</a><br />
                    <a href="#section9">9. CONTROLS FOR DO-NOT-TRACK FEATURES</a><br />
                    <a href="#section10">10. DO WE MAKE UPDATES TO THIS NOTICE?</a><br />
                    <a href="#section11">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a><br />
                    <a href="#section12">12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a><br />
                </div>

                <div>
                    <h4 className="toc-header" id="section1">1. WHAT INFORMATION DO WE COLLECT?</h4>
                    <p className="toc-header-one"> Personal information you disclose to us </p>

                    <p style={{ fontStyle: "italic" }}>  <span style={{ fontWeight: "500" }}>In Short:</span> We collect personal information that you provide to us.
                    </p>

                    <p> We collect personal information that you voluntarily provide to us when you express an interest in obtaining
                            information about us or our products and Services,when you participate in activities on the Services, or otherwise when you contact us.
                    </p>

                    <p>Sensitive Information. We do not process sensitive information.</p>

                    <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

                    <p className="toc-header-one"> Information automatically collected</p>

                    <p style={{ fontStyle: "italic" }}>  <span style={{ fontWeight: "500" }}>In Short:</span>  Some information - such as your Internet Protocol (IP)
                        address and/or browser and device characteristics - is collected automatically when you visit our Services.
                    </p>

                    <p>We automatically collect certain information when you visit, use, or navigate the Services.
                        This information does not reveal your specific identity (like your name or contact information)
                        but may include device and usage information, such as your IP address, browser and device characteristics,
                        operating system, language preferences, referring URLs, device name, country, location,
                        information about how and when you use our Services, and other technical information. This information is primarily needed
                        to maintain the security and operation of our Services,and for our internal analytics and reporting purposes.
                    </p>

                    <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                </div>


                <div>
                    <h4 className="toc-header" id="section2">2. HOW DO WE PROCESS YOUR INFORMATION?</h4>
                    <p className="toc-header-one">Personal information you disclose to us </p>

                    <p style={{ fontStyle: "italic" }}>  <span style={{ fontWeight: "500" }}>In Short:</span>  We process your information to provide,
                        improve, and administer our Services, communicate with you,for security and fraud prevention, and to comply with law.
                        We may also process your information for other purposes with your consent.
                    </p>

                    <p style={{ fontWeight: "600" }}>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</p>
                    <li className="bullet-texts"><span style={{ fontWeight: "600" }} >Marketing communications: </span>
                        We may use your information to contact you about our products and services.
                    </li>
                </div>


                <div>
                    <h4 className="toc-header" id="section3">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>
                    <p style={{ fontStyle: "italic", margin: 0 }}>  <span style={{ fontWeight: "500" }}>In Short:</span>  We may share information in specific situations described in this section and/or with the following third parties.</p>
                    <p>We may need to share your personal information in the following situations:</p>
                   <div className="bullet-text-container">
                    <p className="bullet-texts"> <span style={{ fontWeight: "600" }} > Business Transfers. </span>
                        We may share or transfer your information in connection with, or during negotiations of,
                        any merger, sale of company assets, financing, acquisition of all or a portion of our business to another company.</p>
                   

                   <p className="bullet-texts"><span style={{ fontWeight: "600" }} >Affiliates. </span>
                        We may share your information with our affiliates, in which case we will require
                       those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries,
                       joint venture partners, or other companies that we control or that are under common control with us.
                       </p>

                    <p className="bullet-texts"><span style={{ fontWeight: "600" }}> Business Partners. </span>
                        We may share your information with our business partners to offer you certain products, services, or promotions.
                        </p>
                        </div>
                </div>


                <div>

                    <h4 className="toc-header" id="section4">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
                    <p style={{ fontStyle: "italic", margin: 0 }}>  <span style={{ fontWeight: "500" }}>In Short:</span>  We may use cookies and other tracking technologies to collect and store your information. </p>
                    <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.
                    Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>
                </div>



                <div>

                    <h4 className="toc-header" id="section5">5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h4>
                    <p style={{ fontStyle: "italic", margin: 0 }}>  <span style={{ fontWeight: "500" }}>In Short:</span>  We may transfer, store, and process your information in countries other than your own. </p>
                    <p>Our servers are located in West Europe. If you are accessing our Services from outside,
                        please be aware that your information may be transferred to, stored, and processed by us in our facilities and by
                        those third parties with whom we may share your personal information
                        (see "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" above), in and other countries.
                    </p>

                    <p> If you are a resident in the European Economic Area (EA), United Kingdom (UK), or Switzerland,
                        then these countries may not necessarily have data protection laws or other similar laws as
                        comprehensive as those in your country. However, we will take all necessary measures to protect
                        your personal information in accordance with this privacy notice and applicable law.
                    </p>
                </div>


                <div>
                    <h4 className="toc-header" id="section6">6. HOW LONG DO WE KEEP YOUR INFORMATION? </h4>
                    <p style={{ fontStyle: "italic", margin: 0 }}>  <span style={{ fontWeight: "500" }}>In Short:</span>  We keep your information for as long as necessary to fulfill the purposes outlined
                        in this privacy notice unless otherwise required by law.</p>
                    <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice,
                        unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). </p>
                    <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or,
                        if this is not possible (for example, because your personal information has been stored in backup archives),
                        then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
                </div>


                <div>
                    <h4 className="toc-header" id="section7">7. DO WE COLLECT INFORMATION FROM MINORS? </h4>
                    <p style={{ fontStyle: "italic", margin: 0 }}>  <span style={{ fontWeight: "500" }}>In Short:</span>  We do not knowingly collect data from or market to children under 18 years of age.</p>
                    <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services,
                        you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent
                        to such minor dependent's use of the Services. If we learn that personal information from users less than
                        18 years of age has been collected,we will deactivate the account and take reasonable measures to promptly delete such data from our records.
                        If you become aware of any data we may have collected from children under age 18, please contact us at <a href="mailto:info@openwit.org">info@openwit.org</a></p>

                </div>


                <div>
                    <h4 className="toc-header" id="section8">8. WHAT ARE YOUR PRIVACY RIGHTS?  </h4>
                    <p style={{ fontStyle: "italic", margin: 0 }}>  <span style={{ fontWeight: "500" }}>In Short:</span> You may review, change, or delete your information with us at any time.</p>
                    <p>Withdrawing your consent: If we are relying on your consent to process your personal information,
                        which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time.
                        You can withdraw your consent at any time
                        by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below. </p>
                    <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows,
                        will it affect the processing of any personal information conducted in reliance on lawful processing grounds other than consent.</p>

                </div>

                <div>
                    <h4 className="toc-header" id="section9">9. CONTROLS FOR DO-NOT-TRACK FEATURES   </h4>
                    <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT)
                        feature or setting you can activate to signal your privacy preference not to have data about your online
                        browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and
                        implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or
                        any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online
                        tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
                </div>

                <div>
                    <h4 className="toc-header" id="section10">10. DO WE MAKE UPDATES TO THIS NOTICE? </h4>
                    <p style={{ fontStyle: "italic", margin: 0 }}>  <span style={{ fontWeight: "500" }}>In Short:</span>  Yes, we will update this notice as necessary to stay compliant with relevant laws. </p>
                    <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised"
                        date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice,
                        we may notify you either by prominently posting a notice of such changes or by directly sending you a notification.
                        encourage you to review this privacy notice frequently to be informed of how we are protecting your information.  </p>

                </div>
                <h4 className="toc-header" id="section11">11.  HOW CAN YOU CONTACT US ABOUT THIS NOTICE?   </h4>
                <p>If you have questions or comments about this notice, you may contact us by sending an email to us at: <a href="mailto:info@openwit.org">info@openwit.org</a>  </p>

                <h4 className="toc-header" id="section12">12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?   </h4>
                <p> Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information,
                     or delete it. To request to review, update, or delete your personal information, please fill out and submit a  <a  style={{color:"blue"}}href="/datarequest">data subject access request.</a>  </p>


            </div>
            <Footer />
        </div>
    )
}

export default Privacy
