import React from 'react'
import NavBar from '../NavBar/NavBar';
import ProjectHeader from './ProjectHeader/ProjectHeader';
import ProjectGrid from './ProjectGrid/ProjectGrid';
import Footer from '../Footer/Footer'

export default function ProjectsPage() {
    return (
        <div>

            <NavBar />
            <ProjectHeader />
            <ProjectGrid />
            <Footer />
        </div>
    )
}
