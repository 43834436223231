import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();
    const [shouldScroll, setShouldScroll] = useState(false);


    useEffect(() => {
        // Whenever pathname changes, set shouldScroll to true
        setShouldScroll(true);
    }, [pathname]);

    useLayoutEffect(() => {
        if (shouldScroll) {
            // Scroll to the top of the page
            window.scrollTo(0, 0);
            // Reset shouldScroll to false after scrolling
            setShouldScroll(false);
        }
    }, [shouldScroll]);
    return null;
}