import React, { useState } from 'react'
import './WhatWeDo.css'
import Icon1 from '../../../assets/icon1Land.svg'
import Icon2 from '../../../assets/icon2.png'
import Icon3 from '../../../assets/icon3Land.svg'
export default function WhatWeDo() {

    const [showSecondtext, setSecondText] = useState("first")

    return (
        <section className="what_we_do_container">
            <div className="header_what_we_do">
                <h1>What we do</h1>
            </div>
            <div className="what_content">
                <div className="what_content_left">


                    <p className={`${showSecondtext === "first" ? "what_content_left_1" : "what_content_left_2"}`} onClick={() => setSecondText("first")}>Innovative Software Solutions</p>
                    <p className={`${showSecondtext === "second" ? "what_content_left_1" : "what_content_left_2"}`} onClick={() => setSecondText("second")}>Blockchain Solutions </p>
                    <p className={`${showSecondtext === "third" ? "what_content_left_1" : "what_content_left_2"}`} onClick={() => setSecondText("third")} >Community-Oriented Software</p>


                </div>
                <div className="what_content_right">
                    <div className="what_content_right_1">
                        {showSecondtext === "first" ?
                            <>
                                <img src={Icon1} alt="icon" />
                                <p className="what_content_right_p" >We create next generation software that affects the way people create connections,
                                 do business, learn and collaborate. Through our innovative approach,
                                we strive to provide individuals and organizations with new opportunities to connect and engage more meaningfully.
                                </p>
                            </>
                            : showSecondtext === "second" ?
                                <>
                                    <img src={Icon2} alt="icon" />
                                    <p className="what_content_right_p" >We develop cutting-edge software that utilise the power of the blockchain
                                    to transform processes across different industries. By harnessing the decentralized and secure nature of blockchain,
                                     our products are designed to increase efficiency, transparency, and trust.
                                </p>
                                </>
                                :
                                <>
                                    <img src={Icon3} alt="icon" />
                                    <p className="what_content_right_p" >We develop solutions that are deeply rooted in community engagement and feedback.
                                    Through our commitment to community-driven development,
                                     we aim to foster a collaborative environment where everyone has a voice in shaping the future.
                                </p>
                                </>
                        }
                    </div>

                </div>
            </div>
        </section>

    )
}
