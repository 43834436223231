import React from 'react'
import './TermsofService.css'
import NavBar from '../NavBar/NavBar'
import Footer from '../Footer/Footer'
export default function TermsOfService() {
    return (
        <>
            <NavBar />
            <section className="tos-container">
                <h1>Terms of Service</h1>
                <h2>Introduction</h2>
                <p>Welcome to Openwit Technologies. These Terms of Service ("Terms")
                    govern your access to and use of the software products and services ("Services") provided by Openwit Technologies.
                    By accessing or using any part of our Services, you agree to be bound by these Terms and our Privacy Policy.
                     If you do not agree to all the terms and conditions, then you may not access or use our Services.  </p>

                <p>
                    The website at Openwit.org (the “Site”) is owned by Openwit Technologies Ltd (“Openwit”).
                </p>
                <p>
                    In these Terms, the words “you”, “user” and “your” refer to a visitor of our Site and the terms “we”, “us” and “our” refer to Openwit Technologies Limited.
                </p>
                <p>
                    We reserve the right to change the Site and these Terms at any time, and it is incumbent on you to check these Terms periodically for updates.
                </p>
                <div className="tos-content">
                    <h5>1. Eligibility</h5>
                    <p>You affirm that you are of legal age to enter into these Terms, and you have the legal capacity and authority to agree to these Terms.
                        If you are using the Services on behalf of an organization, you affirm that you have the authority to bind that organization to these Terms. </p>
                </div>
                <div className="tos-content">
                    <h5>2. Service Description</h5>
                    <p>Openwit Technologies develops and offers various software solutions. Our Services are designed to provide users with
                         advanced technological capabilities that are co-community oriented and collaborative. </p>
                </div>
                <div className="tos-content">
                    <h5>3. User Accounts</h5>
                    <p>To access certain features of our Services, you may be required to create an account. You agree to provide accurate, current, and
                        complete information during the registration process and to update such information to keep it accurate, current, and complete. </p>
                </div>
                <div className="tos-content">
                    <h5>4. User Conduct</h5>
                    <p>You agree not to misuse the Services or help anyone else do so. You agree not to, nor attempt to, reverse engineer,
                        decompile, disassemble, or derive source code from our Services unless the terms of our specific open-source services permit you to explicitly do so.
                        You agree to use our Services only in a lawful manner and in compliance with all applicable laws and regulations.</p>

                    <p style={{ padding: '1em 0em 0em' }}>You represent and warrant that you:</p>
                    <div style={{ padding: '.5em 0em 0em 2em' }}>
                        <p>- are of legal age to form a binding contract;</p>
                        <p>- will only use the Site for your own personal and non-commercial use; </p>
                        <p>- will only use the Site in a manner that complies with all applicable laws and regulations; and</p>
                        <p>- Will NOT use the Site in a manner that:</p>
                    </div>

                    <div style={{ padding: '.5em 0em 0em 4em' }}>
                        <p>- infringes the intellectual property rights of any party;</p>
                        <p>- violates the security of any computer network; </p>
                        <p>- runs any form of auto-responder or “spam”;“crawls,” “scrapes,” or “spiders” any data on the Site</p>
                        <p>- copies and/or stores any significant portion of the Site; or </p>
                        <p>- is fraudulent, threatening, harassing, defamatory, obscene, deceptively misleading, or otherwise harmful or unlawful.</p>
                    </div>
                </div>
                <div className="tos-content">
                    <h5>5. Intellectual Property </h5>
                    <p>All intellectual property rights in the Services and their content, including but not limited to designs, text, graphics, pictures,
                         information, data, software, and the selection and arrangement thereof, are owned by Openwit Technologies or its licensors.
                         You are granted a limited, non-exclusive, revocable license to access and use the Services and to download or print a copy of any portion of
                         the content to which you have properly gained access solely for your personal, non-commercial use.
                        </p>

                    <p>Openwit Technologies is the owner of the Openwit Technologies trademark. </p>
                </div>
                <div className="tos-content">
                    <h5>6. Termination </h5>
                    <p>Openwit has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site.
                         The inclusion of any link does not imply endorsement by Openwit of the site.
                     Use of any such linked website is at the user's own risk.</p>
                </div>

                <div className="tos-content">
                    <h5>7. Release re Third-Party Services </h5>
                    <p>When you access a third-party website through the Site, you acknowledge the risks in doing so, and agree that Openwit is not responsible
                        for such risks and has no control over the content and operation of such third-party sites.
                         You release and hold Openwit harmless from any and all liability arising from your use of any third-party website or service. </p>
                </div>
                <div className="tos-content">


                    <h5>8. Disclaimers; Limitation of Liability </h5>
                    <p>The Services are provided "as is"; Openwit Technologies and its suppliers and licensors hereby disclaim all warranties of any kind,
                        express or implied.
                        Openwit Technologies will not be liable for any damages resulting from the use of or inability to use the Services. </p>
                </div>

                <div className="tos-content">


                    <h5>9. Changes to the Terms  </h5>
                    <p>Openwit Technologies reserves the right, at its sole discretion, to modify or replace any part of these Terms.
                        It is your responsibility to check these Terms periodically for changes.  </p>
                </div>

                <div className="tos-content">


                    <h5>Contact Information  </h5>
                    <p>If you have any questions about these Terms, please contact us at <span style={{ color: 'blue' }}>info@openwit.org </span></p>
                </div>





            </section>
            <Footer />
        </>
    )
}
