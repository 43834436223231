import React from 'react'
import './DetailsHero.css'
import eventit from '../../../assets/eventit.svg'
export default function DetailsHero() {
    return (
        <header className="details-container">
            <div className="details-header" >
                <h1>Eventit</h1>
                <p> Where Events Meet Innovation! </p>
                <a href="/#">Visit Website</a>
            </div>
            <div>
                <img className="details-img" src={eventit} alt="" />
            </div>
        </header>
    )
}
