import React from 'react'
import './DetailsContent.css'
import img1 from '../../../assets/detailsImg1.svg'
import img2 from '../../../assets/dimg2.svg'
import img3 from '../../../assets/dimg3.svg'
import img4 from '../../../assets/dimg4.svg'
import img5 from '../../../assets/dimg5.svg'
import img6 from '../../../assets/dimg6.svg'
import img7 from '../../../assets/dimg7.svg'
export default function DetailsContent() {
    return (
        <section className="details-content-container">
            <div className="spacer-content">
                <div className="details-content-left-align">
                    <div className="details-content-text">
                        <h4>Community-Centric Design </h4>
                        <p>Eventit is designed with the community in mind. Our platform serves as a vibrant hub
                            where event enthusiasts, organizers, and vendors come together. Discover and join communities
                            tailored to your interests, whether it's music, sports, or professional networking. Engage in
                            conversations,
                     share experiences, and build connections that go beyond the boundaries of the event.</p>
                    </div>
                    <div>
                        <img src={img1} alt="" />
                    </div>
                </div>
            </div>

            <div className="spacer-content">
                <div className="details-content-left-align">
                    <div>
                        <img src={img2} alt="" />
                    </div>
                    <div className="details-content-text">
                        <h4>Attendee Networking </h4>
                        <p>Eventit goes beyond the event day by facilitating meaningful connections
                            before and after the event. Our networking features allow attendees to discover and
                             connect with others who share similar interests or professional goals.
                             Forge connections that extend beyond the
                         event, creating a network that lasts well into the future.</p>
                    </div>

                </div>
            </div>




            <div className="spacer-content">
                <div className="details-content-left-align">
                    <div className="details-content-text">
                        <h4>Socially Driven Recommendations </h4>
                        <p>Eventit is designed with the community in mind. Our platform serves as a vibrant hub
                            where event enthusiasts, organizers, and vendors come together. Discover and join communities
                            tailored to your interests, whether it's music, sports, or professional networking. Engage in
                            conversations,
                     share experiences, and build connections that go beyond the boundaries of the event.</p>
                    </div>
                    <div>
                        <img src={img3} alt="" />
                    </div>
                </div>
            </div>

            <div className="spacer-content">
                <div className="details-content-left-align">
                    <div>
                        <img src={img4} alt="" />
                    </div>
                    <div className="details-content-text">
                        <h4>Integrated Social Media </h4>
                        <p>Easily share your event experiences beyond Eventit by seamlessly integrating with
                            your favorite social media platforms. Amplify the social aspect of your events by sharing updates,
                            inviting friends,
                        and extending the conversation to a wider audience..</p>
                    </div>

                </div>
            </div>

            <div className="spacer-content">
                <div className="details-content-left-align">
                    <div className="details-content-text">
                        <h4>Web 3 and Blockchain-Powered </h4>
                        <p>Eventit is built on the cutting-edge technology of Web 3 and powered by the transparency and
                            security of blockchain. This means your event experiences are not only interactive but also safe
                            and reliable. Say goodbye to ticket bots, fraud, and ticket scalping.
                            Eventit ensures a level playing field for all attendees and organizers.
                    </p>
                    </div>
                    <div>
                        <img src={img5} alt="" />
                    </div>
                </div>
            </div>



            <div className="spacer-content">
                <div className="details-content-left-align">
                    <div>
                        <img src={img6} alt="" />
                    </div>
                    <div className="details-content-text">
                        <h4>Seamless Event Management </h4>
                        <p>Organizing an event has never been this easy. Eventit offers a comprehensive suite of
                            event management tools, from creating and promoting events to tracking ticket sales,
                            managing guest lists and connecting with vendors.
                            Enjoy a hassle-free event management experience with Eventit.
                    </p>
                    </div>

                </div>
            </div>


            <div className="spacer-content">
                <div className="details-content-left-align">
                    <div className="details-content-text">
                        <h4>A Vendor Ecosystem Like No Other </h4>
                        <p>Our extensive vendor ecosystem includes a diverse range of professionals,
                            from caterers and decorators to sound engineers and photographers.
                            These experts are handpicked, trusted,
                             and verified, ensuring that you work with only the best in the business.
                    </p>
                    </div>
                    <div>
                        <img src={img7} alt="" />
                    </div>
                </div>
            </div>


        </section>
    )
}
