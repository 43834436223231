import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

// const override = {
//    color= rgba(22, 22, 235, 1)
// };


const Spinner = () => {

    let color = ("rgba(22, 22, 235, 1)");

    return (
        <div className="sweet-loading">

            <ClipLoader
                color={color}
                loading={true}
                // cssOverride={override}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </div>
    );
}

export default Spinner;
