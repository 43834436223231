import React from 'react'
import './ProjectHeader.css'
import { useLocation } from 'react-router-dom';


export default function ProjectHeader() {
    const location = useLocation();

    const isActive = (pathname) => {
        return location.pathname === pathname ? "active" : "";
    }
    return (
        <header className="project-header">
            <h1>Our Products – Innovative, Empowering, Community-Oriented</h1>
            <div className="project-header-links" >
                <ul>
                    <li onClick={() => window.location.href = "/projects"} className={isActive("/projects")}>
                        <a className={isActive("/projects")} href="/projects">All</a>
                    </li>
                    <li onClick={() => window.location.href = "/websites"} className={isActive("/websites")}>
                        <a className={isActive("/websites")} href="/websites">Websites</a>
                    </li>
                    <li onClick={() => window.location.href = "/apps"} className={isActive("/apps")}>
                        <a className={isActive("/apps")} href="/apps">Apps</a>
                    </li>
                    <li onClick={() => window.location.href = "/tools"} className={isActive("/tools")}>
                        <a className={isActive("/tools")} href="/tools">Tools</a>
                    </li>
                </ul>
            </div>
        </header>
    )
}
