import React, { useState, useEffect } from 'react'
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import arrow from '../../assets/modicon.svg'
import './Contacts.css';
import Spinner from '../../assets/Spinner';


const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "390px",
    height: "235",
    bgcolor: "background.paper",
    borderRadius: '4px',
    boxShadow: 24,
    outline: "none",

};

export default function Contacts() {
    const [open, setOpen] = React.useState(false);
    let [loading, setLoading] = useState(false);

    const [error, setError] = useState('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errorMail, setErrorMail] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(false);
        }, 3000);

        return () => clearTimeout(timer);
    }, [open]);
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (
            fullName.length === 0 ||
            email.length === 0 ||
            message.length === 0
        ) {

            setError("This field is required");
            setErrorMail('')
            return
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {

            setErrorMail("Please enter a valid email address");

            return;
        }
        try {
            setLoading(true);
            // const res = await fetch("https://openwit.azurewebsites.net/contact", {
            const res = await fetch('http://localhost:3001/contact', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: fullName,
                    email: email,
                    message: message,
                }),
            });
            setLoading(false);
            if (res.ok) {
                setOpen(true); // Display success message or handle success case
                setEmail("");
                setFullName("");
                setMessage("");
                setError('');
                setErrorMail('');
            } else {
                // Handle non-success status codes, e.g., display an error message
                console.error('Error:', res.statusText);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error:', error);
        }
    }





    return (
        <>
            <NavBar />

            <section className="contact-container" >
                <h1>Get in Touch</h1>
                <p>We know you have a voice, we’d love to hear it.</p>
                <form onSubmit={handleSubmit}>

                    <div className="contact-form">

                        <div className="contact-text">

                            <h4>Name</h4>

                            <input
                                className="text-border"
                                type="text"
                                placeholder="Your name"
                                name="fullName"
                                id="fullName"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                            />
                            {error && fullName.length === 0 ?
                                <label htmlFor="fullName" style={{ color: "red" }}>{error}</label>
                                : ""}
                        </div>

                        <div className="contact-text">
                            <h4>Email</h4>

                            <input
                                className="text-border"
                                type="email"
                                name="email"
                                id="email"
                                value={email}
                                placeholder="Your email address"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {error && email.length === 0 ?
                                <label htmlFor="email" style={{ color: "red" }}>{error}</label>
                                : ""}
                            {errorMail ?
                                <label htmlFor="email" style={{ color: "red" }}>{errorMail}</label>
                                : ""}

                        </div>
                    </div>

                    <div className="contact-message">
                        <h4>Message</h4>
                        <textarea
                            placeholder="Type your message here"
                            name="message"
                            id="message"
                            value={message}
                            cols="30"
                            rows="15"
                            width="100%"
                            onChange={(e) => setMessage(e.target.value)}
                        >
                        </textarea>

                        {error && message.length === 0 ?
                            <label htmlFor="message" style={{ color: "red" }}>{error}</label>
                            : ""}
                    </div>
                    {loading ? <Spinner style={{ fontSize: '2em' }} />
                        :

                        <button type="submit">Submit message</button>}
                </form>
            </section>
            <Footer />

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="modal-content-top">

                        <div className="modal-close" >
                            <ClearIcon style={{ fontSize: "1rem" }} onClick={handleClose} />
                        </div>
                        <div className="modal-top-img">
                            <img src={arrow} alt="" />
                        </div>

                    </div>
                    <div className="modal-content">
                        <h4>Way to go!</h4>
                        <p>Your message has been submitted and sent successfully.</p>
                    </div>

                </Box>
            </Modal>
        </>
    )
}
