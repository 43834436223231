import React, { useState, useEffect } from 'react';
import './DataRequest.css';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { FaCheck } from "react-icons/fa6";
import Spinner from '../../assets/Spinner';
import Footer from '../Footer/Footer';
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import arrow from '../../assets/modicon.svg';

const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "390px",
    height: "235",
    bgcolor: "background.paper",
    borderRadius: '4px',
    boxShadow: 24,
    outline: "none",

};
const DataRequest = () => {


    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('CCPA');
    const options = ['CCPA', 'CTDPA', 'GDPR', 'UCPA', 'VCDPR', 'Other'];
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState(false);
    let [loading, setLoading] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleOptionClick = (option) => {

        setSelectedOption(option);
        setIsOpen(false);
    };
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        requester: '',
        law: selectedOption,
        request_type: '',
        details: '',
        confirm_info: false
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };


    useEffect(() => {

        setFormData(prevFormData => ({
            ...prevFormData,
            law: selectedOption
        }));
    }, [selectedOption])

    useEffect(() => {

        const timer = setTimeout(() => {

            setError("");
            if (open) {
                setOpen(false)
            }
        }, 5000);

        return () => clearTimeout(timer);

    }, [error, open]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {

            setError("Please enter a valid email address");
            return;
        }
        try {
            setLoading(true)
            // let res = await fetch("https://openwit.azurewebsites.net/datarequest", {
            let res = await fetch('http://localhost:3001/datarequest', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData)

            })
            if (res.ok) {
                setLoading(false)
                setOpen(true)
            }
            else {
                console.log("error")
            }


        } catch (error) {
            setLoading(false)

        }
    };

    const Spacer = () => {
        return <div style={{ marginTop: '10px' }} />;
    };



    return (
        <>
            <div className="data-request">
                <div className="data-request-header">

                    <h1>Data Subject Access Request form</h1>
                    <p>Please fill in the information below. The website administrator  or data protection officer will be notified</p>

                    <p>of your request within 24 hours, and will need an appropriate amount of time to respond.</p>
                </div>

                <div className="welcome-head">
                    <h3>Website</h3>
                    <p>Openwit Website</p>
                </div>

                <form onSubmit={handleSubmit}>
                    <div>
                        <h3>Your Name</h3>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder='Enter Your Name'
                            required
                            className="data-input-one"
                        />

                        <h3>What email address is associated with your information on the above website?</h3>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            id="email"
                            placeholder='Enter Your Email'
                            className="data-input-one"
                            required
                        />
                        {error ?
                            <label htmlFor="email" style={{ color: "red" }}>{error}</label>
                            : ""}
                    </div>
                    <h3>You are submitting the request as</h3>
                    <label>
                        <input
                            type="radio"
                            name="requester"
                            value="The person, or the parent/guardian of the person, whose name appears above"
                            id="person"
                            required
                            onChange={handleChange} />
                        The person, or the parent/guardian of the person, whose name appears above
                 </label>

                    <Spacer />

                    <label>
                        <input
                            type="radio"
                            name="requester"
                            value="An agent authorized by the consumer to make this request on their behalf"
                            required
                            onChange={handleChange} />
                        An agent authorized by the consumer to make this request on their behalf
                </label><Spacer />

                    <h3>Under the rights of which law are you making this request?</h3>

                    <div className="custom-select-container">
                        <div className="selected-option" onClick={toggleDropdown}>
                            {selectedOption}
                            {isOpen ? <IoIosArrowUp style={{ fontSize: '20px' }} /> : <IoIosArrowDown style={{ fontSize: '20px' }} />}
                        </div>
                        {isOpen && (
                            <ul className="options">
                                {options.map((option) => (
                                    <li key={option} onClick={() => handleOptionClick(option)}
                                        className={option === selectedOption ? "selected-option-list" : ""}
                                    >
                                        {option === selectedOption && <FaCheck style={{ fontSize: '20px' }} />}
                                        {option}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    <h3>I am submitting a request to </h3>
                    <label>
                        <input
                            type="radio"
                            name="request_type"
                            value="Know what information is being collected from me"
                            required
                            onChange={handleChange} />
                        Know what information is being collected from me
                </label>

                    <Spacer />

                    <label>
                        <input
                            type="radio"
                            name="request_type"
                            value="Have my information deleted"
                            required
                            onChange={handleChange} />
                        Have my information deleted
                </label>
                    <Spacer />
                    <label>
                        <input
                            type="radio"
                            name="request_type"
                            value="Opt out of having my data sold to third parties"
                            required
                            onChange={handleChange} />
                        Opt out of having my data sold to third parties
                 </label>

                    <Spacer />

                    <label>
                        <input
                            type="radio"
                            name="request_type"
                            value="Access my personal information"
                            required
                            onChange={handleChange} />
                        Access my personal information
                 </label>

                    <Spacer />

                    <label>
                        <input
                            type="radio"
                            name="request_type"
                            value="Fix inaccurate information"
                            required
                            onChange={handleChange} />
                        Fix inaccurate information
                 </label>

                    <Spacer />


                    <label>
                        <input
                            type="radio"
                            name="request_type"
                            value="Receive a copy of my personal information"
                            required
                            onChange={handleChange} />
                        Receive a copy of my personal information
                </label>

                    <Spacer />

                    <label>
                        <input
                            type="radio"
                            name="request_type"
                            value="Opt out of having my data shared for cross-context behavioral advertising"
                            required
                            onChange={handleChange} />

                        Opt out of having my data shared for cross-context behavioral advertising
                </label>

                    <Spacer />

                    <label>
                        <input
                            type="radio"
                            name="request_type"
                            value="Limit the use and disclosure of my sensitive personal information"
                            required
                            onChange={handleChange}
                        />
                        Limit the use and disclosure of my sensitive personal information
                </label>

                    <Spacer />

                    <label>
                        <input
                            type="radio"
                            name="request_type"
                            value="Other"
                            required
                            onChange={handleChange}
                        />
                        Other (please specify in the comment box below)
                </label><Spacer />

                    <h3>Please leave details regarding your action request or question</h3>
                    <textarea
                        onChange={handleChange}
                        name="details"
                        id="details"
                        cols="5"
                        rows="10"
                        required
                        className="data-textarea"
                        placeholder="Please leave details regarding your action request or question">

                    </textarea>

                    <h3>I confirm that</h3>
                    <label>
                        <input
                            type="checkbox"
                            name="confirm_info"
                            value="true"
                            required
                            onChange={handleChange}
                        />
                        Under penalty of perjury, I declare all the above information to be true and accurate.
                 </label>
                    <Spacer />
                    <label>
                        <input
                            type="checkbox"
                            name="confirm_info"
                            value="true"
                            required
                            onChange={handleChange}
                        />
                        I understand that the deletion or restriction of my personal data is irreversible
                        and may result in termination of service with Openwit Website.
                 </label>
                    <Spacer />
                    <label>
                        <input type="checkbox"
                            name="confirm_info"
                            value="true"
                            required
                            onChange={handleChange}
                        />
                        I understand that I will be required to validate my request by email,
                        and I may be contacted in order to complete the request.
                </label>
                    <Spacer />
                    <div className="data-btn">

                        {loading ? <Spinner style={{ fontSize: '2em' }} /> : <button type="submit">Submit</button>}

                    </div>

                </form>

            </div>
            <Footer />


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="modal-content-top">

                        <div className="modal-close" >
                            <ClearIcon style={{ fontSize: "1rem" }} onClick={handleClose} />
                        </div>
                        <div className="modal-top-img">
                            <img src={arrow} alt="" />
                        </div>

                    </div>
                    <div className="modal-content">
                        <h4>Way to go!</h4>
                        <p>Your request has been sent successfully</p>
                    </div>

                </Box>
            </Modal>
        </>
    )
}

export default DataRequest
