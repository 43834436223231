import React from 'react'
import './AboutHero.css'
import star from '../../../assets/Staricon.png'
import accord from '../../../assets/accord.svg'
const AboutHero = () => {
    return (
        <div>
            <div className="about-hello">
                <img src={star} alt="" />
                <p>Well, hello!</p>
            </div>
            <p className="about-hello-text">
                We are creating connections and reigniting our sense of community.
            </p>

            <img className="about-hero-img" src={accord} alt="" />

            <p className="about-text-one">
                We started out as a team of engineers who observed the connectivity
                gap among millennials in our city. Despite the high use of social media,
                more and more young people complained of a lack of community. We  thought we could solve that problem through technology–essentially, helping people connect in-person, through digital technology. With
                time we have discovered that the gap is multi-faceted/multi-dimensional (exists in different areas of people’s lives and in different age
                groups).
            </p>
            <p className="about-text-one">
                Hence, we are offering solutions to help people connect more, have fun
                and create memorable experiences beyond the confines of a smart
                device. Our solutions are engineered to achieve this across different
                aspects of life and across all age groups and social classes.
            </p>
        </div>
    )
}

export default AboutHero
