import React from 'react'
import './OurProjects.css'
import img1 from '../../../assets/LandingProjectimg1.png'
import img2 from '../../../assets/LandingProjectimg2.png'
import { Link } from 'react-router-dom'
export default function OurProjects() {
    return (
        <section className="project_container">
            <div className="header_project">
                <h1>Our Projects</h1>
            </div>
            <div className="project_content">

                <div className="project_content_left">
                    <Link style={{ textDecoration: 'none' }} to="/comingsoon/Event it">
                        <img src={img2} alt="icon" />
                        <div className="project_content_box" >
                            <p className="project_content_text">Event it</p>
                            <p className="project_content_text1">Coming soon</p>
                        </div>
                    </Link>
                    <p>A web app design for events </p>

                </div>

                <div className="project_content_left">
                    <Link style={{ textDecoration: 'none' }} to="/comingsoon/Event it">
                        <img src={img1} alt="icon" />
                        <div className="project_content_box" >
                            <p className="project_content_text">Event it</p>
                            <p className="project_content_text1">Coming soon</p>
                        </div>
                    </Link>
                    <p >A web app design for events </p>

                </div>

            </div>

            <div className="header_project">
                <Link style={{ textDecoration: 'none' }} to="/projects">
                    <button >Tour all projects</button>
                </Link>
            </div>


        </section>
    )
}
