import React, { useState, useEffect } from 'react'
import './ComingSoon.css'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import arrow from '../../assets/modicon.svg';
import '../Contacts/Contacts.css'

const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "390px",
    height: "235",
    bgcolor: "background.paper",
    borderRadius: '4px',
    boxShadow: 24,
    outline: "none",

};


const ProductSubscribe = () => {
    const [email, setEmail] = useState(null);
    const [statusMessage, setStatusMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const tagName = useParams();
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
            setError('Please enter a valid email address');
            return;
        }
        setIsSubmitting(true);
        try {
            // const response = await axios.post('https://openwit.azurewebsites.net/subscribe/', {
            const response = await axios.post('http://localhost/3001/subscribe/', {
                email,
                tagName: tagName.product,
            });

            if (response.status === 200) {
                setStatusMessage('Subscription successful')
                setOpen(true)

                setEmail('');
            } else {
                setError('Failed to subscribe');
            }
        } catch (error) {

            setError('Error: Failed to subscribe');
        } finally {
            setIsSubmitting(false);

        }
    }

    useEffect(() => {
        let timeout;
        if (error || statusMessage) {
            timeout = setTimeout(() => {
                setError('');
                setOpen(false)
                setStatusMessage('');

            }, 5000);
        }

        return () => clearTimeout(timeout);
    }, [error, statusMessage]);

    return (
        <>
            <div>
                <div className="product-sub-container">


                    <input
                        type='email'
                        id='email'
                        placeholder='Type email here'
                        value={email}
                        className="product-sub-input"
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    {isSubmitting ? 'Sending...' :
                        <p>
                            className="product-sub-btn"
                            onClick={handleSubmit}>Notify me</p>}

                </div>

                {error && <label htmlFor="email">{error}</label>}

            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="modal-content-top">

                        <div className="modal-close" >
                            <ClearIcon style={{ fontSize: "1rem" }} onClick={handleClose} />
                        </div>
                        <div className="modal-top-img">
                            <img src={arrow} alt="" />
                        </div>

                    </div>
                    <div className="modal-content">
                        <h4>Way to go!</h4>
                        <p>{statusMessage}</p>
                    </div>

                </Box>
            </Modal>
        </>
    )
}

export default ProductSubscribe