import React from 'react';
import './GlobalCommunity.css';

export default function GlobalCommmunity() {
    return (
        <section className="community_container">
            <div className="community_content">
                <p className="community_content_top">We enrich global community experiences through technology</p>
                <p className="community_content_middle">
                    We offer solutions that help people connect more, have fun
                    and create memorable experiences beyond the confines of a smart
                    device. Our solutions are engineered to achieve this across different aspects of life and across all age groups and social classes.
            </p>
                <button onClick={() => window.location.href = "/about"}> Get to know us</button>
            </div>
        </section >
    )
}
