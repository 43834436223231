import React from 'react';
import facebook from '../../assets/facebook.svg';
import Twitter from '../../assets/Twitter.svg';
import instagram from '../../assets/instagram.svg';
import linkedin from '../../assets/linkedin.svg';
import './Footer.css';
import MailChimp from './MailChimp';

const Footer = () => {

    return (
        <footer className="footer_container">
            <div className="container_footer_main">

                <MailChimp />

                <div className="footer_links">
                    <ul>
                        <li><a href="/projects" >Projects</a> </li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/contact">Contact</a></li>
                    </ul>

                    <ul>
                        <li><a href="/privacy" >Privacy Notice</a> </li>
                        <li><a href="/tos">Terms of service</a></li>
                    </ul>

                </div>
            </div>


            <div className="footer_social_media">
                <ul>
                    <li><a href="https://www.instagram.com/openwittech/" > <img src={instagram} alt="insta" />Instagram</a></li>
                    <li><a href="https://www.facebook.com/profile.php?id=61556456675346">  <img src={facebook} alt="fb" />Facebook</a></li>
                    <li><a href="https://www.linkedin.com/in/openwit-technologies-7093a12b5/">  <img src={linkedin} alt="linkedin" />LinkedIn</a></li>
                    <li><a href="https://twitter.com/OpenwitTech">  <img src={Twitter} alt="x" />X/Twitter</a></li>
                </ul>
            </div>

        </footer>
    )
}

export default Footer;