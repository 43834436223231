import React from 'react'
import GIF from '../../../assets/Animation.gif'
import './HeroSection.css'
export default function HeroSection() {
    return (
        <div className="hero_section">

            <p>
                Openwit is the
                leading provider of
                innovative and collaborative
                technology solutions
            </p>
            <img src={GIF} alt="heroGif" />
        </div>
    )
}
