import React, { useState, useEffect } from 'react'
import LogoFooter from '../../assets/openwitlogo1.svg';
import EmailArrow from '../../assets/emailArrow.png';
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import arrow from '../../assets/modicon.svg';
import './Footer.css';


const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "390px",
    height: "235",
    bgcolor: "background.paper",
    borderRadius: '4px',
    boxShadow: 24,
    outline: "none",

};

const Subscribe = ({ message, status, onValidated }) => {
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = useState(null);
    const [error, setError] = useState(null);

    const handleFormSubmit = () => {
        setError(null);
        if (!email || !/^\S+@\S+\.\S+$/.test(email)) {
            setError('Please enter a valid email address');
            return;
        }

        const isFormValidated = onValidated({ EMAIL: email, tags: 1049599 });
        return isFormValidated;

    }
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {

        const timer = setTimeout(() => {
            setError("");
            setOpen(false)
        }, 5000);

        return () => clearTimeout(timer);

    }, [error, status]);

    useEffect(() => {

        if (status === 'success') {
            setOpen(true);
        }
    }, [status]);

    return (
        <>
            <div className="logo_container_footer">
                <img src={LogoFooter} alt="logo_1" />
                <p>Subscribe to know when we launch a new project and get latest updates.</p>
                <div className="input_footer_box">
                    <input
                        type='email'
                        id='email'
                        placeholder='Your Email Address'
                        className="input_footer"
                        onChange={(event) => setEmail(event.target.value)}
                    />
                    {'sending' === status ? <span className="sending">sending...</span> :
                        <img src={EmailArrow} onClick={handleFormSubmit} alt="send_arrow" />}


                </div>

                <div>

                    {'error' === status || error ? (
                        <div
                            className="sub-error"
                            dangerouslySetInnerHTML={{ __html: error || message }}
                        />
                    ) : null}

                </div>

            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="modal-content-top">

                        <div className="modal-close" >
                            <ClearIcon style={{ fontSize: "1rem" }} onClick={handleClose} />
                        </div>
                        <div className="modal-top-img">
                            <img src={arrow} alt="" />
                        </div>

                    </div>
                    <div className="modal-content">
                        <h4>Way to go!</h4>
                        <p>{message}</p>
                    </div>

                </Box>
            </Modal>
        </>
    )
}

export default Subscribe