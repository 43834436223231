import React from 'react'
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import Subscribe from './Subscribe';

const MailChimp = () => {

    const MAILCHIMP_URL = process.env.REACT_APP_MAILCHIMP_URL;

    return (
        <div>
            <MailchimpSubscribe
                url={MAILCHIMP_URL}
                render={(props) => {
                    const { subscribe, status, message } = props || {};
                    return (
                        <Subscribe
                            status={status}
                            message={message}
                            onValidated={formData => subscribe(formData)}
                        />
                    );
                }}
            />
        </div>
    );
};

export default MailChimp;