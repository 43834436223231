import React from 'react'
import './App.css'
import LandingPage from './components/LandingPage/LandingPage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './components/About/About';
import ProjectsPage from './components/ProjectsPage/ProjectsPage';
import Contacts from './components/Contacts/Contacts';
import ProuctDetails from './components/ProductDetails/ProuctDetails';
import ScrollToTop from './components/ScrollToTop';
import TermsOfService from './components/TermsofService/TermsOfService';
import Websites from './components/ProjectsPage/Websites/Websites';
import AppsPage from './components/ProjectsPage/AppsPage/AppsPage';
import Tools from './components/ProjectsPage/Tools/Tools';
import ComingSoon from './components/ComingSoon/ComingSoon'
import Privacy from './components/Privacy/Privacy';
import DataRequest from './components/DataRequest/DataRequest';


function App() {


  return (

    <Router>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/contact" element={<Contacts />} />
          <Route path="/eventitproduct" element={<ProuctDetails />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/websites" element={<Websites />} />
          <Route path="/apps" element={<AppsPage />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/privacy/" element={<Privacy />} />
          <Route path="/datarequest" element={<DataRequest />} />
          <Route path="/comingsoon/:product/" element={<ComingSoon />} />
        </Routes>
      </div>
    </Router>
  
  )
}

export default App
