import React from 'react'
import './NextProject.css'
import comp from '../../../assets/computer.svg'
export default function NextProject() {
    return (
        <div className="next-project-container">
            <h5>Next Project</h5>
            <h3>Eventit</h3>
            <div className="next-project-img">
                <img src={comp} alt="" />
            </div>
        </div>
    )
}
