import React from 'react'
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import HeroSection from './HeroSection/HeroSection';
import WhatWeDo from './WhatWedo/WhatWeDo';
import OurProjects from './OurProjects/OurProjects';
import GlobalCommmunity from './GlobalCommunity/GlobalCommmunity';
import Faq from './FAQ/Faq';
const LandingPage = () => {
    return (
        <div>
            <NavBar />
            <HeroSection />
            <WhatWeDo />
            <OurProjects />
            <GlobalCommmunity />
            <Faq />
            <Footer />
        </div>
    )
}
export default LandingPage
