import React, { useState } from 'react';
import './Faq.css'

export default function Faq() {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const questions = [
        {
            question: 'Question 1',
            answer: 'We create next generation software that affects the way people create connections, do business, learn and collaborate. We create next generation software that affects the way people create connections, do business,learn and collaborate'},

        {
            question: 'Question 2',
            answer: 'We create next generation software that affects the way people create connections, do business, learn and collaborate. We create next generation software that affects the way people create connections, do business,learn and collaborate'},

        {
            question: 'Question 3',
            answer: 'We create next generation software that affects the way people create connections, do business, learn and collaborate. We create next generation software that affects the way people create connections, do business,learn and collaborate'},
    ];


    return (
        <div className="faq-content">
            <div className="header_project">
                <h1>FAQs</h1>
            </div>
            {questions.map((item, index) => (
                <div key={index} className="accordion-item">
                    <div className={`accordion-header ${openIndex === index ? 'open' : ''}`} onClick={() => toggleAccordion(index)}>
                        <div className="accordion-question">{item.question}
                        </div>

                        <div className="accordion-icon">

                            <div className="plus-sign" >
                                <div className="horizontal-line"></div>
                                <div className={`vertical-line ${openIndex === index ? 'open' : 'close'}`}></div>
                            </div>
                        </div>
                    </div>

                    <div className={`accordion-content ${openIndex === index ? 'open' : ''}`}>
                        <p>
                            {item.answer}
                        </p>
                    </div>

                </div>
            ))}
        </div>
    );
}
