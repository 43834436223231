import React from 'react';
import './Mission.css'
const Mission = () => {
    return (
        <div className="about-mission">
            <div className="mission-grid">
                <h3>Our Mission</h3>
                <p>
                    To create next generation software that affects the way we create connections, do business, learn and collaborate.
                </p>
            </div>
            <div className="mission-grid">
                <h3>Our Purpose</h3>
                <p>
                    To repair and improve broken systems and fix the pressing problems of our generation.
                </p>
            </div>
            <div className="mission-grid">
                <h3>Our Vision</h3>
                <p>
                    To be one of the leading providers of innovative and collaborative technology solutions.
                </p>
            </div>
            <div className="mission-grid">
                <h3>Our Belief</h3>
                <p>
                    Meaningful relationships are necessary for a healthy lifestyle.
                </p>
            </div>

        </div>
    )
}

export default Mission
