import React from 'react'
import image1 from '../../assets/emptyfolder.svg'
import './NoEvent.css'
export default function NoEvent() {
    return (
        <div className="no-event">
            <img src={image1} alt="" />
            <h2>No websites yet</h2>
            <p>We have no websites yet, stay tuned for future sites.</p>
        </div>
    )
}
