import React from 'react'
import './JoinCommunity.css'
export default function JoinCommunity() {
    return (
        <div className="join-comm-container">
            <h4>Join the Eventit Community </h4>
            <p className="join-comm-text1">We invite you to join our growing community of event enthusiasts,
                organizers, and attendees who believe that events should be about
                connections, not complications. Discover a world of exciting events,
                build meaningful connections, and experience the future of event management with Eventit.
                At Eventit, we're not just redefining events; we're revolutionizing them.
                 Join us on this incredible journey,
                 and let's make every event an unforgettable experience.
            </p>
            <p className="join-comm-text1"> Get started with Eventit and be a part of the future of events!</p>
            <a className="join-comm-btn" href="/#">Get Started</a>
        </div>
    )
}
