import React from 'react'
import NavBar from '../../NavBar/NavBar';
import ProjectHeader from '../ProjectHeader/ProjectHeader';
import Footer from '../../Footer/Footer';
import NoEvent from '../../NoEvent/NoEvent';

export default function AppsPage() {
    return (
        <div>
            <NavBar />
            <ProjectHeader />
            <NoEvent />
            <Footer />
        </div>
    )
}