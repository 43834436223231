import React from 'react'
// import img1 from '../../../assets/LandingProjectimg1.png'
import img2 from '../../../assets/LandingProjectimg2.png'
// import img3 from '../../../assets/img3.svg'
// import img4 from '../../../assets/img4.svg'
// import img5 from '../../../assets/img5.svg'
// import img6 from '../../../assets/img6.svg'
// import img7 from '../../../assets/img7.svg'
// import img8 from '../../../assets/img8.svg'
// import img9 from '../../../assets/img9.svg'
// import img10 from '../../../assets/img10.svg'
// import img11 from '../../../assets/img11.svg'
// import img12 from '../../../assets/img12.svg'
// import rightArrow from '../../../assets/rightarr.svg'
// import leftArrow from '../../../assets/leftarr.svg'

import "./ProjectGrid.css"
import { Link } from 'react-router-dom';
export default function ProjectGrid() {
    return (
        <section className="grid-container">
            <div className="grid-content">
                <div className="project_grid_left">
                    <Link style={{ textDecoration: 'none' }} to="/comingsoon/Event it">
                        <img src={img2} alt="icon" />
                    </Link>
                    <div className="project_grid_box" >
                        <p className="project_grid_text">Event it</p>
                        <p className="project_grid_text1">Coming soon</p>
                    </div>
                    <p>A social web3 platform for building communities around events. </p>

                </div>
                {/* <div className="project_grid_left">
                    <Link style={{ textDecoration: 'none' }} to="/comingsoon/Event it">
                        <img src={img1} alt="icon" />
                    </Link>
                    <div className="project_grid_box" >
                        <p className="project_grid_text">Event it</p>
                        <p className="project_grid_text1">Coming soon</p>
                    </div>
                    <p>A web app design for events </p>
                </div>
                <div className="project_grid_left">
                    <Link style={{ textDecoration: 'none' }} to="/comingsoon/DreamDwell">
                        <img src={img3} alt="icon" />
                    </Link>
                    <div className="project_grid_box" >
                        <p className="project_grid_text">DreamDwell</p>
                        <p className="project_grid_text1">Coming soon</p>
                    </div>
                    <p>A web app design for real estate </p>
                </div>

                <div className="project_grid_left">
                    <Link style={{ textDecoration: 'none' }} to="/comingsoon/Mindle">
                        <img src={img4} alt="icon" />
                    </Link>
                    <div className="project_grid_box" >
                        <p className="project_grid_text">Mindle</p>
                        <p className="project_grid_text1">Coming soon</p>
                    </div>
                    <p>A web app design for mental health and wellnesss </p>
                </div>
                <div className="project_grid_left">
                    <Link style={{ textDecoration: 'none' }} to="/comingsoon/Crafthaven">
                        <img src={img5} alt="icon" />
                    </Link>
                    <div className="project_grid_box" >
                        <p className="project_grid_text">Crafthaven</p>
                        <p className="project_grid_text1">Coming soon</p>
                    </div>
                    <p>A web app design for handmade crafts </p>
                </div>
                <div className="project_grid_left">
                    <Link style={{ textDecoration: 'none' }} to="/comingsoon/Techtrend">
                        <img src={img6} alt="icon" />
                    </Link>
                    <div className="project_grid_box" >
                        <p className="project_grid_text">Techtrend</p>
                        <p className="project_grid_text1">Coming soon</p>
                    </div>
                    <p>A web app design for technology</p>
                </div>
                <div className="project_grid_left">
                    <Link style={{ textDecoration: 'none' }} to="/comingsoon/WeRent">
                        <img src={img7} alt="icon" />
                    </Link>
                    <div className="project_grid_box" >
                        <p className="project_grid_text">WeRent</p>
                        <p className="project_grid_text1">Coming soon</p>
                    </div>
                    <p>A web app design for rentals </p>
                </div>
                <div className="project_grid_left">
                    <Link style={{ textDecoration: 'none' }} to="/comingsoon/Ecos">
                        <img src={img8} alt="icon" />
                    </Link>
                    <div className="project_grid_box" >
                        <p className="project_grid_text">Ecos</p>
                        <p className="project_grid_text1">Coming soon</p>
                    </div>
                    <p>A web app design for sustainability </p>
                </div>
                <div className="project_grid_left">
                    <Link style={{ textDecoration: 'none' }} to="/comingsoon/Good Skin Club">
                        <img src={img9} alt="icon" />
                    </Link>
                    <div className="project_grid_box" >
                        <p className="project_grid_text">Good Skin Club</p>
                        <p className="project_grid_text1">Coming soon</p>
                    </div>
                    <p>A web app design for skincare </p>
                </div>
                <div className="project_grid_left">
                   
                    <Link style={{ textDecoration: 'none' }} to="/comingsoon/Scribbe">
                        <img src={img10} alt="icon" />
                    </Link>
                    <div className="project_grid_box" >
                        <p className="project_grid_text">Scribbe</p>
                        <p className="project_grid_text1">Coming soon</p>
                    </div>
                    <p>A web app design for writing and publishing </p>
                </div>
                <div className="project_grid_left">
                    <Link style={{ textDecoration: 'none' }} to="/comingsoon/Pettie">
                        <img src={img11} alt="icon" />
                    </Link>
                    <div className="project_grid_box" >
                        <p className="project_grid_text">Pettie</p>
                        <p className="project_grid_text1">Coming soon</p>
                    </div>
                    <p>A web app design for pet care</p>
                </div>
                <div className="project_grid_left">
                    <Link style={{ textDecoration: 'none' }} to="/comingsoon/Treep">
                        <img src={img12} alt="icon" />
                    </Link>
                    <div className="project_grid_box" >
                        <p className="project_grid_text">Treep</p>
                        <p className="project_grid_text1">Coming soon</p>
                    </div>
                    <p>A web app design for travel </p>
                </div>
            </div>
            <div className="paginator-box" >

                <div className="paginator-box-go" >
                    <p>Go to page</p>
                    <input type="number"
                        value={1}
                        className="paginator-input"
                    />
                    <p>/ 1</p>
                    <p className="paginator-btn">Go</p>
                </div>
                <div className="paginator-box-arrow">
                    <img src={leftArrow} alt="" />
                    <img src={rightArrow} alt="" />
                </div> */}
            </div>
        </section>
    )
}
