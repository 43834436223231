import React from 'react'
import './CoreValues.css';
import value1 from '../../../assets/value1.svg';
import value2 from '../../../assets/value2.svg'
import value3 from '../../../assets/value3.svg'
import value4 from '../../../assets/value4.svg'
import value5 from '../../../assets/value5.svg'

const CoreValues = () => {
    return (
        <div>
            <div className="value-header">
                <h1>Our Core Values</h1>
                <p>Bringing people together to build strong, lasting connections </p>
                <p> through shared experiences and a sense of belonging</p>

            </div>
            <div className="value-content">

                <div className="value-box">
                    <div className="value-grid">
                        <h3>Innovative</h3>
                        <p>
                            We are constantly coming up with new and exciting ways to help people connect and live their best lives.
                        </p>
                        <img src={value1} alt="" />
                    </div>

                    <div className="value-grid">
                        <h3>Empowering</h3>
                        <p>
                            Our focus is supporting and enabling customers, employees, and other stakeholders to achieve their goals and reach their full potential.
                         </p>
                        <img src={value2} alt="" />
                    </div>

                </div>




                <div className="value-grid">
                    <h3>Community</h3>
                    <p>
                        Our focus is being connected, supportive, and involved in building strong relationships with the local, national, and global community.
                    </p>
                    <img src={value3} alt="" />
                </div>


                <div className="value-box">
                    <div className="value-grid">
                        <h3>Transparency</h3>
                        <p>
                            We consistently communicate openly and clearly, and are willing to share information and answer questions.
                        </p>
                        <img src={value4} alt="" />
                    </div>

                    <div className="value-grid">
                        <h3>Integrity</h3>
                        <p>
                            We believe in honesty, fairness, and respect. We will tell our customers the truth, we won’t manipulate our users.
                        </p>
                        <img src={value5} alt="" />
                    </div>

                </div>


            </div>



        </div>
    )
}

export default CoreValues

