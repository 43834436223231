import React from 'react'
import NavBar from '../../NavBar/NavBar';
import ProjectHeader from '../ProjectHeader/ProjectHeader';
import Footer from '../../Footer/Footer';
import { Link } from 'react-router-dom';
import img2 from '../../../assets/LandingProjectimg2.png'
export default function Websites() {
    return (
        <div>
            <NavBar />
            <ProjectHeader />
            <section className="grid-container">
                <div className="grid-content">
                    <div className="project_grid_left">
                        <Link style={{ textDecoration: 'none' }} to="/comingsoon">
                            <img src={img2} alt="icon" />
                        </Link>
                        <div className="project_grid_box" >
                            <p className="project_grid_text">Event it</p>
                            <p className="project_grid_text1">Coming soon</p>
                        </div>
                        <p>A social web3 platform for building communities around events. </p>

                    </div>
                </div>
            </section>
            <Footer />

        </div>
    )
}
