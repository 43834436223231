import React from 'react'
import Logo from '../../assets/openwitlogo.svg'
import './Navbar.css'
import { useLocation } from 'react-router-dom';
import { CiMenuBurger } from "react-icons/ci"
import { IoMdClose } from "react-icons/io";
export default function NavBar() {
    const location = useLocation();

    const isActive = (pathname) => {
        return location.pathname === pathname ? "active" : "";
    };

    function showsidebar() {
        const sidebar = document.querySelector('.sidebar');
        sidebar.style.display = 'flex'
    }
    function hidesidebar() {
        const sidebar = document.querySelector('.sidebar');
        sidebar.style.display = 'none'
    }
    return (

        <header className="header_nav">
            <a href="/"><img src={Logo} alt="logo" /></a>
            <nav>

                <ul className="sidebar">
                    <IoMdClose style={{ fontSize: '1.2rem', padding: '0em 3.5em' }} onClick={() => hidesidebar()} />
                    <li>
                        <a href="/projects" className={isActive("/projects")}>Projects</a>
                    </li>
                    <li>
                        <a href="/about" className={isActive("/about")}>About</a>
                    </li>
                    <li>
                        <a href="/contact" className={isActive("/contact")}>Contact</a>
                    </li>

                </ul>


                <ul>
                    <li className="hideOnMobile">
                        <a href="/projects" className={isActive("/projects")}>Projects</a>
                    </li>
                    <li className="hideOnMobile">
                        <a href="/about" className={isActive("/about")}>About</a>
                    </li>
                    <li className="hideOnMobile">
                        <a href="/contact" className={isActive("/contact")}>Contact</a>
                    </li>


                    <CiMenuBurger className="menu-button" onClick={() => showsidebar()} />

                </ul>
            </nav>
        </header>

    )
}
