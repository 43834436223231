import React from 'react'
import './ComingSoon.css'
import wave2 from '../../assets/wave2.svg';
import wave1 from '../../assets/wave1.svg';
import ProductSubscribe from './ProductSubscribe';
import { useParams } from 'react-router-dom';

const ComingSoon = () => {
    const productName = useParams();
    return (

        <div>
            <div className="coming-soon">
                <div className="coming-soon-content">
                    <h1>Good things come to those who wait</h1>
                    <div className="coming-soon-product">
                        <p>{productName.product} will be here before you know it. Enter your email below to get notified when it launches.</p>

                    </div>
                </div>

                <ProductSubscribe />

            </div>


            <div className="logos">
                <div className="logos-slide">
                    <img src={wave1} alt="waveform" />
                </div>


                <div className="logos-slide">
                    <img src={wave2} alt="waveform" />
                </div>

            </div>

        </div>

    )
}

export default ComingSoon
