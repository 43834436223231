import React from 'react'
import './StoryandApproach.css'
export default function StoryandApproach() {
    return (
        <section className="story-container">
            <div className="story-content">
                <h3>Our story</h3>
                <p>It’s 2021, on a chilly night. Ticket bots have rendered popular ticket platforms
                     non-responsive. Thousands of fans who have stayed up late to get their event tickets
                     are deeply frustrated. Unable to buy tickets at the original prices, event goers must
                     now buy them at inflated prices or miss out. Two of these disenfranchised individuals
                     set out to build a fairer, community-driven event ecosystem. Eventit was thus conceived
                     as a blockchain-powered solution to eliminate ticket bots, ensuring that event tickets
                     find their way into the hands of true fans and that proceeds end up in the pockets of
                     organizers. It's a story of resilience, fairness, and a commitment to making events
                      accessible to all.
                    Eventit is more than just an event management platform; it is your gateway
                     to a new era of event management. </p>
            </div>
            <div className="story-content">
                <h3>Our Approach</h3>
                <p>In a world of digital connections, Eventit is here to reignite the social spark of events.
                     We understand that events are not just about dates and venues; they're about shared experiences,
                      connections, and memories.
                    With Eventit, we're putting the "social" back into social events.  </p>
            </div>
        </section>
    )
}
