import React from 'react'
import DetailsHero from './DetailsHero/DetailsHero';
import NavBar from '../NavBar/NavBar'
import Footer from '../Footer/Footer'
import StoryandApproach from './StoryandApproach/StoryandApproach';
import DetailsContent from './DetailsContent/DetailsContent';
import JoinCommunity from './JoinCommunity/JoinCommunity';
import NextProject from './NextProject/NextProject';
export default function ProuctDetails() {
    return (
        <div>
            <NavBar />
            <DetailsHero />
            <StoryandApproach />
            <DetailsContent />
            <JoinCommunity />
            <NextProject />
            <Footer />
        </div>
    )
}
